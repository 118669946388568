/*eslint-disable */
// import sModel from '../../Model/s'
import request from '../../Utils/curl'
// import Utility from '../../Utils/utility';

const UserResume = {
  /**
   * user_resume_build
   */
  async user_resume_build (context, userId) {
    try{
      let post_data = new FormData();
      post_data.append('user_id', userId)
      return await request.curl(context, "user_resume_build", post_data)
        .then(async response => {
          return response
      });
    }
    catch(err){
      console.error('Exception occurred at user_profile_excList() and Exception:',err.message)
    }
  },
  /**
   * user_resume_add_exc
   */
  async user_resume_add_exc (context, excArr=[], section) {
    try{
      let post_data = new FormData();
      post_data.append('exc_arr', JSON.stringify(excArr))
      post_data.append('section', section)

      return await request.curl(context, "user_resume_add_exc", post_data)
        .then(async response => {
          return response
      });
    }
    catch(err){
      console.error('Exception occurred at user_resume_add_exc() and Exception:',err.message)
    }
  },
  /**
   * user_resume_remove_exc
   */
  async user_resume_remove_exc (context, excArr=[]) {
    try{
      let post_data = new FormData();
      post_data.append('exc_arr', JSON.stringify(excArr))
      return await request.curl(context, "user_resume_remove_exc", post_data)
        .then(async response => {
          return response
      });
    }
    catch(err){
      console.error('Exception occurred at user_resume_remove_exc() and Exception:',err.message)
    }
  },
  /**
   * user_resume_enhance
   */
  async user_resume_enhance (context, sectionToEnhance) {
    try{
      let post_data = new FormData();
      post_data.append('section_to_enhance', JSON.stringify(sectionToEnhance))
      return await request.curl(context, "user_resume_enhance", post_data)
        .then(async response => {
          return response
      });
    }
    catch(err){
      console.error('Exception occurred at user_resume_remove_exc() and Exception:',err.message)
    }
  },
  /**
   * user_resume_replace_ai_resp
   */
  async user_resume_replace_ai_resp (context, excObj) {
    let post_data = new FormData();
    
    for (let key in excObj) {
      if (excObj[key] || typeof excObj[key] == "number") {
        post_data.append(key, excObj[key]);
      }
    }
    return await request.curl(context, "user_resume_replace_ai_resp", post_data)
      .then(async response => {
        return response
    });
  }
}

export {UserResume}